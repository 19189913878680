<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <lparte-novedad-form
          :id="routeParams.idlparte_novedad"
          :punto-obligatorio="puestoServicio && puestoServicio.novedad_punto_obligatorio"
          :idpuesto-servicio="puestoServicio && puestoServicio.idpuesto_servicio"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteNovedadForm from '../components/LparteNovedadForm'
import Data from './LparteNovedadEditData'

export default {
  components: {
    LparteNovedadForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      puestoServicio: {},
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar novedad'
      this.$set(this, 'puestoServicio', await Data.selectPuestoServicio(this, this.routeParams.idparte_novedad))
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(this, this.routeParams.idlparte_novedad, this.formData)
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.lparteNovedad, this.routeParams.idlparte_novedad)
      this.$appRouter.go(-1)
    },
  },
}
</script>
